import React, { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { SessionProvider } from "next-auth/react";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

import { ExperimentsProvider } from "@/providers/ExperimentsProvider";

import { init } from "@/salesHelpers/sentry";

import { useApollo } from "../API/apolloClient";

type PageProps = {
	Component: (...props: any[]) => React.ReactElement;
	pageProps: Record<string, unknown>;
	err: any;
};

import "../styles/global.scss";
import "../../polyfills";

init();

const App = ({ Component, pageProps, err }: PageProps) => {
	useEffect(() => {
		if (!window.location.href.includes("localhost")) {
			amplitude.init(process.env.NEXT_PUBLIC_AMP || "");

			const sessionReplayTracking = sessionReplayPlugin({
				sampleRate: 1,
			});
			amplitude.add(sessionReplayTracking);
		}
	}, []);

	const apolloClient = useApollo(pageProps.initialApolloState);

	return (
		<SessionProvider>
			<ApolloProvider client={apolloClient}>
				<ExperimentsProvider>
					<Component {...pageProps} err={err} />
				</ExperimentsProvider>
			</ApolloProvider>
		</SessionProvider>
	);
};

// Disable as this is for next.js
// eslint-disable-next-line import/no-default-export
export default App;
