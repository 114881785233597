// IF CHANGING THESE, UPDATE THE ACCOUNT PICK IN DATO

export const DISCOUNT_CODE_ADDITIONAL_DOG = "ADDITIONALDOG50";
export const DISCOUNT_CODE_ADDITIONAL_DOG_ONGOING = "ADDITIONALDOG-ONGOING-5";

export const REDUCED_COMMISSION_CODE_PREFIX = "EVENTF2F-50";

// MAKE SURE FIRST ITEM IN THIS ARRAY IS THE DEFAULT VOUCHER WE WANT TO APPLY
export const salesVouchers = [
	{
		code: "EVENTF2F-30",
		description: "30% off your first, 10% off your second",
	},
	{
		code: "EVENTF2F-30-MULTI",
		description: "30% off your first, 10% off multidogs forever",
	},
	{
		code: "EVENTF2F-5025",
		description: "50% off your first, 25% off your second",
	},
];
